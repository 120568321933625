

function App() {
  return (
    <>
    <h1 className="Title">Cornelius Allison</h1>
    <h2 className="Title">Problem Solver/React Developer</h2>
    
    <div className="Center"><img className="Logo" src="Logo Borderless.png" alt="Logo" /></div>

    </>
  );
}

export default App;
